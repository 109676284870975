<template>
<div class="privacy">
  <Header/>
  <div class="privacy-con" v-if="!isEn">
    <h1 class="title">服务条款</h1>
    <p>在使用Little Star 或LITTLESTARCLOUD.COM(以下简称“我们”、“我们”或“我们的”)运营的<a class="link" href="https://www.littlestarcloud.com">https://www.littlestarcloud.com</a> 网站之前，请仔细阅读这些条款和条件(以下简称“条款”、“条款和条件”)。
    </p>
    <p>
        您访问和使用本服务的条件是您接受和遵守本条款。本条款适用于所有访问或使用本服务的访问者、用户和其他人。
    </p>
    <p><b>
        访问或使用本服务，即表示您同意受本条款约束。如果您不同意本条款的任何部分，则您不得访问本服务。
    </b></p>
    <p>
       违反以下任何条款，您的帐户将被终止。尽管我们禁止相关违反协议和法律的行为和服务内容，您理解并同意我们不能对发布在服务上的内容负责。您同意自行承担使用本服务的风险。
    </p>
    <h5>I. 账号条款</h5>
    <ol>
        <li>您必须年满13岁方可使用本服务。</li>
        <li>
            你一定是个人类。由“机器人”或其他自动方法注册的帐户是不允许的。
        </li>
        <li>
           您的登录只能被一个人使用，一个单一的登录共享给多人使用是不允许的。
        </li>
        <li>
           您有责任维护您的GitHub帐户和密码的安全。我方不能也不会对因贵方未能遵守本担保义务而造成的任何损失或损害负责。</li>
        <li>
           您对在您的账户下创造的所有内容和活动负责。
        </li>
        <li>
            您不得将本服务用于任何非法或未经授权的目的。您在使用本服务时，不得违反您管辖范围内的任何法律(包括但不限于著作权法或商标法)。
        </li>
    </ol>
    <h5>II. 付款,退款条款</h5>
    <ol>
        <li>
            所有付费计划必须输入有效的信用卡。免费试用时不需要信用卡。
        </li>
        <li>
           所有费用不包括税务当局征收的所有税费、征费或关税，您应负责支付所有此类税费、征费或关税。
        </li>
        <li>
            您可以在7天内以任何理由退款，发送邮件到service@littlestarcloud.com即可。我们将在一个工作日内处理。
        </li>
        <li>
            续订订阅是不退款的，如果您不想续订，请提前取消您的订阅。
        </li>
    </ol>
    <h5>个人信息</h5>
    <p>您提交的个人信息受我们的隐私政策管辖。<a class="link" href="/privacy">Privacy Policy</a>.
    </p>
    <h5>禁止使用</h5>
    <p>
        当出现以下情况，禁止您使用本产品或本网站:
    </p>
    <ul>
        <li>为任何非法目的;

        <li>引诱他人从事或者参与违法行为的;</li>

        <li>违反任何法律或地方条例;</li>

        <li>侵犯或侵犯我们或他人的知识产权;</li>

        <li>基于性别、性取向、宗教、民族、种族、年龄、国籍或残疾进行骚扰、辱骂、侮辱、伤害、诽谤、诽谤、诋毁、恐吓或歧视;</li>

        <li>提交虚假或者误导性的信息;</li>

        <li>上传或传输将可能以影响本服务或任何相关网站、其他网站或互联网的功能或操作的的病毒或任何其他类型的恶意代码;</li>

        <li>收集或追踪他人的个人信息;</li>

        <li>滥发垃圾邮件、网络钓鱼、无诱饵欺骗、借口、爬取信息、爬虫;</li>

        <li>出于淫亵或不道德的目的;</li>

        <li>干扰或规避本服务或任何相关网站、其他网站或互联网的安全功能。如果您违反任何被禁止的用途，我们保留终止您对本服务或任何相关网站的使用的权利。</li>
    </ul>
    <h4>免责声明</h4>
    <p>
        在适用法律允许的最大范围内，我们明确放弃对网站、服务和软件的任何和所有明示或暗示的担保和条件，包括但不限于对适销性、可销售质量、适合某一特定目的或不侵权的任何暗示担保或条件。您同意，您使用本网站、服务和软件的风险由您自行承担，本网站、服务和任何软件是在“现状”和“所有过错”的基础上提供的，没有任何形式的明示或暗示的保证。在不限制前述规定的情况下，我们不保证本网站、服务和/或软件的运行将符合您的要求，或将不间断或无错误。
    </p>
    <h4>责任限制</h4>
    <p>
        在任何情况下，本公司概不就本网站、服务及/或软件承担任何责任
    </p>
    <ol>
        <li>
           在紧接导致该等责任发生的事件之前的十二(12)个月期间，您实际支付给我们的费用的总和以外的任何金额;
        </li>
        <li>
            任何利益损失、用户内容或其他数据丢失或损坏，或未履行任何义务，包括但不限于善意和合理的维护;
        </li>
        <li>
            任何间接的、附带的、惩罚性的、特殊的、惩戒性的或间接的损害。
        </li>
    </ol>
    <h4>可分割性</h4>
    <p>
       如果本服务条款的任何条款被确定为非法、无效或不可执行，则该条款应在适用法律允许的最大范围内可执行，不可执行的部分应被视为与本服务条款分离。该决定不应影响任何其他剩余条款的有效性和可执行性。
    </p>
    <h4>终止</h4>
    <p>
       我们可以出于任何原因(包括但不限于违反本条款)，在无需事先通知或承担责任的情况下，自行决定立即终止或暂停您的帐户，并禁止您访问本服务。
    </p>
    <p>
        本条款的所有条款在其性质上应在终止后继续有效，包括但不限于所有权条款、保证免责声明、赔偿和责任限制。
    </p>
    <h4>变化</h4>
    <p>
        我们保留随时更新和更改服务条款而不另行通知的权利。任何增加或增强当前服务的新功能，包括新工具和资源的发布，均应遵守《服务条款》。在任何此等变更后继续使用本服务即构成您同意此等变更。
    </p>
    <p>
        您在任何修订生效后继续访问或使用我们的服务，即表示您同意接受修订后的条款的约束。如果您不同意新条款，您将不再被授权使用本服务。
    </p>
    <h4>联系我们</h4>
    <p>
        如果您对本协议有任何问题，请联系我们
        <a class="link" href="mailto:service@littlestarcloud.com">service@littlestarcloud.com</a>.
    </p>
  </div>
  <div class="privacy-con" v-else>
    <h1 class="title">Terms of service</h1>

    <p>Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before
        using the Little Star or
        <a class="link" href="https://www.littlestarcloud.com">https://www.littlestarcloud.com</a> website operated
        by <b>LITTLESTARCLOUD.COM</b> ("us","we", or "our").
    </p>
    <p>
        Your access to and use of the Service is conditioned on your acceptance of and
        compliance with these Terms. These Terms apply to all visitors, users and others who
        access or use the Service.
    </p>
    <p><b>
        By accessing or using the Service you agree to be bound by these Terms. If you disagree
        with any part of the terms then you may not access the Service.
    </b></p>
    <p>
        Violation of any of the terms below will result in the termination of your Account.
        While we prohibits such conduct and Content on the Service, you understand and agree
        that we cannot be responsible for the Content posted on the Service. You agree to use
        the Service at your own risk.
    </p>
    <h5>I. Account Terms</h5>
    <ol>
        <li>You must be 13 years or older to use this Service.</li>
        <li>
            You must be a human. Accounts registered by “bots” or other automated methods are
            not permitted.
        </li>
        <li>
            Your login may only be used by one person — a single login shared by multiple people
            is not permitted.
        </li>
        <li>
            You are responsible for maintaining the security of your GitHub account and
            password. We cannot and will not be liable for any loss or damage from your failure
            to comply with this security obligation.
        </li>
        <li>
            You are responsible for all Content posted and activity that occurs under your
            account.
        </li>
        <li>
            You may not use the Service for any illegal or unauthorized purpose. You must not,
            in the use of the Service, violate any laws in your jurisdiction (including but not
            limited to copyright or trademark laws).
        </li>
    </ol>
    <h5>II. Payment, Refunds Terms</h5>
    <ol>
        <li>
            All paid plans must enter a valid credit card. Credit card are not required when you
            are in free trial.
        </li>
        <li>
            All fees are exclusive of all taxes, levies, or duties imposed by taxing
            authorities, and you shall be responsible for payment of all such taxes, levies, or
            duties, excluding only United States (federal or state) taxes.
        </li>
        <li>
            You can get the refund in 7 days for any reason when you subscribe, just send us
            an email at <a class="link" href="mailto:service@littlestarcloud.com">service@littlestarcloud.com</a>.
            We'll deal it in one work day.
        </li>
        <li>
            The renewal subscription is un-refundable, please cancel your subscription
            in advance if you don't want to renew.
        </li>
    </ol>
    <h5>Personal Information</h5>
    <p>
        Your submission of personal information is governed by our
        <a class="link" href="/privacy">Privacy Policy</a>.
    </p>
    <h5>Prohibited Uses</h5>
    <p>
        You are prohibited from using the product or the website:
    </p>
    <ul>
        <li>for any unlawful purpose;</li>
        <li>to solicit others to perform or participate in any unlawful acts;</li>
        <li>to violate any international, federal, provincial or state regulations, rules, laws,
            or local ordinances;
        </li>
        <li>to infringe upon or violate our intellectual property rights or the
            intellectual property rights of others;
        </li>
        <li>to harass, abuse, insult, harm, defame,
            slander, disparage, intimidate, or discriminate based on gender, sexual orientation,
            religion, ethnicity, race, age, national origin, or disability;
        </li>
        <li>to submit false or misleading information;</li>
        <li>to upload or transmit viruses or any other type of malicious
            code that will or may be used in any way that will affect the functionality or
            operation of the Service or of any related website, other websites, or the Internet;
        </li>
        <li> to collect or track the personal information of others;
        </li>
        <li> to spam, phish, pharm, pretext, spider, crawl, or scrape;
        </li>
        <li>for any obscene or immoral purpose;</li>
        <li>to interfere with or circumvent the security features of the Service or any related
            website, other websites, or the Internet.
            We reserve the right to terminate your use of the Service or
            any related website for violating any of the prohibited uses.
    </li></ul>
    <h4>Disclaimer Of Warranties</h4>
    <p>
        To the maximum extent permitted by applicable law, we expressly disclaims any and
        all warranties and conditions, express or implied, regarding the site, services and
        software, including, but not limited to, any implied warranties or conditions of
        merchantability, merchantable quality, fitness for a particular purpose or
        noninfringement. You agree that your use of the site, services and software are at your
        own sole risk and that the site, services and any software are provided on an “as is”
        and “with all faults” basis, without warranties of any kind, either express or implied.
        Without limiting the foregoing, we does not warrant that the operation of the
        site, services and/or software will meet your requirements or will be uninterrupted or
        error-free.
    </p>
    <h4>Limitation Of Liability</h4>
    <p>
        In no event shall we be liable with respect to the site, services and/ or
        software for
    </p>
    <ol>
        <li>
            any amount in the aggregate in excess of the fees you have actually
            paid to we during the twelve (12) month period immediately preceding the event(s)
            giving rise to such liability;
        </li>
        <li>
            any lost profits, lost or damaged user content or other data, or failure to meet
            any duty, including without limitation good faith and reasonable care;
        </li>
        <li>
            any indirect, incidental, punitive, special, exemplary or
            consequential damages of any kind whatsoever.
        </li>
    </ol>
    <h4>Severability</h4>
    <p>
        In the event that any provision of these Terms of Service is determined to be unlawful,
        void or unenforceable, such provision shall nonetheless be enforceable to the fullest
        extent permitted by applicable law, and the unenforceable portion shall be deemed to be
        severed from these Terms of Service, such determination shall not affect the validity
        and enforceability of any other remaining provisions.
    </p>
    <h4>Termination</h4>
    <p>
        We may terminate or suspend your account and bar access to the Service immediately,
        without prior notice or liability, under our sole discretion, for any reason whatsoever
        and without limitation, including but not limited to a breach of the Terms.
    </p>
    <p>
        All provisions of the Terms which by their nature should survive termination shall
        survive termination, including, without limitation, ownership provisions, warranty
        disclaimers, indemnity and limitations of liability.
    </p>
    <h4>Changes</h4>
    <p>
        We reserves the right to update and change the Terms of Service from time to
        time without notice. Any new features that augment or enhance the current Service,
        including the release of new tools and resources, shall be subject to the Terms of
        Service. Continued use of the Service after any such changes shall constitute your
        consent to such changes.
    </p>
    <p>
        By continuing to access or use our Service after any revisions become effective, you
        agree to be bound by the revised terms. If you do not agree to the new terms, you are no
        longer authorized to use the Service.
    </p>
    <h4>Contact us</h4>
    <p>
        If You have any questions about this Terms, please contact us at
        <a class="link" href="mailto:service@littlestarcloud.com">service@littlestarcloud.com</a>.
    </p>
  </div>
  <Footer/>
</div>
</template>
<script>
// @ is an alias to /src
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      isEn: true,
    }
  },
  watch: {
    lang() {
      this.isEn = this.lang.indexOf('en') !== -1
    },
  },
  mounted() {
    this.isEn = this.lang.indexOf('en') !== -1
  },
}
</script>

<style lang="scss" scoped>
.privacy-con{
  padding: 36px;
  color: #484852;
  max-width: 1000px;
  margin: 0 auto 0;
  font-size: 16px;
  .title{
    font-size: 48px;
    color: #484852;
    line-height: 48px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 48px;
  }
  h2{
    font-size: 32px;
    color: #484852;
    line-height: 48px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  h4, h5{
    font-size: 26px;
    color: #484852;
    line-height: 48px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  p{
    color: #646574;
    margin-bottom: 32px;
  }
  .link{
    color: #3582fb;
  }
  li{
    color: #646574;
    line-height: 18px;
    margin-left: 24px;
    list-style: disc;
  }
  ul{
    margin-bottom: 24px;
  }
}
</style>
